const Select = {
  variants: {
    filled: {
      field: {
        height: {base: 10, md: 12},
        borderRadius: 'small',
        backgroundColor: 'layers.appLevel2',
        color: 'text.basic',
        '> option': {
          color: 'text.basic',
          backgroundColor: 'layers.appLevel2',
        },
        _hover: {
          backgroundColor: 'layers.appLevel2',
        },
        _focus: {
          backgroundColor: 'layers.appLevel2',
        },
      },
      icon: {
        color: 'text.basic',
      },
    },
  },
  defaultProps: {
    variant: 'filled',
  },
}

export default Select
