import {create} from 'zustand'
import {SELECTED_GENERATION_OPTION} from '../constants/playground'

export const modelPlaygroundDefaults = {
  input: '',
  output: '',
  maxLength: 70,
  temperature: 0.7,
  topP: 1,
  autoReset: true,
  isToxic: false,
  displayOptions: false,
  isGenerating: false,
  selectedGenerationType: SELECTED_GENERATION_OPTION.GENERATE_FIRST,
}

type State = {
  input: string
  output: string
  maxLength: number
  temperature: number
  topP: number
  autoReset: boolean
  isToxic: boolean
  displayOptions: boolean
  isGenerating: boolean
  setInput: (input: string) => void
  setOutput: (output: string) => void
  setMaxLength: (maxLength: number) => void
  setTemperature: (temperature: number) => void
  setTopP: (topP: number) => void
  setAutoReset: (autoReset: boolean) => void
  setIsToxic: (isToxic: boolean) => void
  setDisplayOptions: (displayOptions: boolean) => void
  setIsGenerating: (isGenerating: boolean) => void
  setDefaults: () => void
  resetPlayground: () => void
  selectedGenerationType: SELECTED_GENERATION_OPTION
  setSelectedGenerationType: (selectedGenerationType: SELECTED_GENERATION_OPTION) => void
}

const modelStore = create<State>(set => ({
  input: modelPlaygroundDefaults.input,
  output: modelPlaygroundDefaults.output,
  maxLength: modelPlaygroundDefaults.maxLength,
  temperature: modelPlaygroundDefaults.temperature,
  topP: modelPlaygroundDefaults.topP,
  autoReset: modelPlaygroundDefaults.autoReset,
  isToxic: modelPlaygroundDefaults.isToxic,
  displayOptions: modelPlaygroundDefaults.displayOptions,
  isGenerating: modelPlaygroundDefaults.isGenerating,
  selectedGenerationType: modelPlaygroundDefaults.selectedGenerationType,
  setInput: input => set({input}),
  setOutput: output => set({output}),
  setMaxLength: maxLength => set({maxLength}),
  setTemperature: temperature => set({temperature}),
  setTopP: topP => set({topP}),
  setAutoReset: autoReset => set({autoReset}),
  setIsToxic: isToxic => set({isToxic}),
  setDisplayOptions: displayOptions => set({displayOptions}),
  setIsGenerating: isGenerating => set({isGenerating}),
  setSelectedGenerationType: selectedGenerationType => set({selectedGenerationType}),
  setDefaults: () =>
    set({
      maxLength: modelPlaygroundDefaults.maxLength,
      temperature: modelPlaygroundDefaults.temperature,
      topP: modelPlaygroundDefaults.topP,
    }),
  resetPlayground: () =>
    set({
      input: modelPlaygroundDefaults.input,
      output: modelPlaygroundDefaults.output,
      maxLength: modelPlaygroundDefaults.maxLength,
      temperature: modelPlaygroundDefaults.temperature,
      topP: modelPlaygroundDefaults.topP,
      autoReset: modelPlaygroundDefaults.autoReset,
      isToxic: modelPlaygroundDefaults.isToxic,
      displayOptions: modelPlaygroundDefaults.displayOptions,
      isGenerating: modelPlaygroundDefaults.isGenerating,
      selectedGenerationType: modelPlaygroundDefaults.selectedGenerationType,
    }),
}))

export default modelStore
