import {SupabaseClient} from '@supabase/supabase-js'
import {useContext, createContext} from 'react'

export const Context = createContext<SupabaseClient | undefined>(undefined)
export const Provider = Context.Provider
export const Consumer = Context.Consumer
Context.displayName = 'SupabaseContext'

export function useClient(): SupabaseClient {
  const client = useContext(Context)
  if (client === undefined) throw Error('No client has been specified using Provider.')
  return client
}
