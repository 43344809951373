const Heading = {
  baseStyle: {
    color: 'text.dark',
    fontStyle: 'regular',
    overflowWrap: 'anywhere',
  },
  variants: {
    large: {
      fontSize: ['extraExtraLargeMobile', null, 'extraExtraLargeDesktop'],
      lineHeight: ['extraLargeHeightMobile', null, 'extraLargeHeightDesktop'],
      fontWeight: 'semiBold',
    },
    medium: {
      fontSize: ['extraLargeMobile', null, 'extraLargeDesktop'],
      lineHeight: ['largeHeightMobile', null, 'largeHeightDesktop'],
      fontWeight: 'semiBold',
    },
    small: {
      fontSize: ['largeMobile', null, 'largeDesktop'],
      lineHeight: ['mediumHeightMobile', null, 'mediumHeightDesktop'],
      fontWeight: 'semiBold',
    },
  },

  defaultProps: {
    variant: 'medium',
  },
}

export default Heading
