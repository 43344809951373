const text = {
  baseStyle: {
    fontSize: 'regular',
    color: 'text.dark',
    wordWrap: 'anywhere',
    position: 'relative',
  },
  variants: {
    extraSmall: {
      fontSize: ['extraSmallMobile', null, 'extraSmallDesktop'],
      lineHeight: ['extraSmallHeightMobile', null, 'extraSmallHeightDesktop'],
      wordWrap: 'anywhere',
    },
    small: {
      fontSize: ['smallMobile', null, 'smallDesktop'],
      lineHeight: ['smallHeightMobile', null, 'smallHeightDesktop'],
      wordWrap: 'anywhere',
      fontWeight: 'normal',
    },
    smallBold: {
      fontSize: ['smallMobile', null, 'smallMobile'],
      lineHeight: ['smallHeightMobile', null, 'smallHeightDesktop'],
      fontWeight: 'semiBold',
      wordWrap: 'anywhere',
    },
    medium: {
      fontSize: ['mediumMobile', null, 'mediumDesktop'],
      lineHeight: 'mediumHeight',
      wordWrap: 'anywhere',
      fontWeight: 'normal',
    },
    mediumBold: {
      fontSize: ['mediumMobile', null, 'mediumDesktop'],
      lineHeight: 'mediumHeight',
      fontWeight: 'semiBold',
      wordWrap: 'anywhere',
    },
    large: {
      fontSize: ['largeMobile', null, 'largeDesktop'],
      lineHeight: 'mediumHeight',
      wordWrap: 'anywhere',
    },
    largeBold: {
      fontSize: ['largeMobile', null, 'largeDesktop'],
      lineHeight: 'mediumHeight',
      wordWrap: 'anywhere',
      fontWeight: 'semiBold',
    },
  },
  defaultProps: {
    variant: 'medium',
  },
}

export default text
