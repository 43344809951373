const Checkbox = {
  baseStyle: {
    label: {
      color: 'text.basic',
      overflowWrap: 'anywhere',
      fontSize: {base: 'mediumM', md: 'medium'},
      lineHeight: 'mediumHeight',
    },
    control: {
      h: '18px',
      w: '18px',
      background: 'brand.white',
      borderWidth: 0,
      transition: 'all, 150ms',
      ring: 'none',
      borderRadius: 'extraExtraSmall',
      _hover: {
        background: 'brand.primary',
      },
      _focus: {
        ring: 'none',
      },
      _checked: {
        background: 'brand.primary',
        _hover: {
          background: 'brand.primary',
        },
      },
    },
  },
}
export default Checkbox
