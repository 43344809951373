const radii = {
  infinite: '100%',
  big: '16px',
  medium: '14px',
  small: '10px',
  extraSmall: '8px',
  extraExtraSmall: '4px',
}

export default radii
