export enum SUPPORTED_LANGUAGES {
  fr = 'fr',
  en = 'en',
  de = 'de',
}

export type DISPLAYED_LANGUAGE = {
  value: SUPPORTED_LANGUAGES.fr
  text: string
}

export const isSupported = (value: any) => Object.values(SUPPORTED_LANGUAGES).includes(value)
