const breakpoints = {
  base: '0px',
  sm: '300px',
  md: '500px',
  lg: '768px',
  xl: '1100px',
  '2xl': '1500px',
}

export default breakpoints
