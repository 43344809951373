import {Divider, Flex, Menu, MenuButton, MenuItem, MenuList} from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {
  CEDILLE_HOME,
  CEDILLE_PRIVACY_POLICY,
  CEDILLE_TERMS_OF_USE,
  getCedilleRedirectURL,
  getProfileTabURL,
  ROUTE_PRICING,
  ROUTE_ROOT,
  USER_PROFILE_TABS,
  USER_SIGN_IN,
  USER_SIGN_UP,
} from '../../constants/routes'
import {setRedirectionCookies} from '../../utils/loginHelper'
import {useAuth} from '../../hooks/useAuth'
import useTranslation from 'next-translate/useTranslation'
import {useIsMobile} from '../../hooks/useIsMobile'
import {useSignOut} from '../../hooks'

const UserMenu = () => {
  const router = useRouter()
  const [, signOut] = useSignOut()

  const {t} = useTranslation('menu')

  const {user} = useAuth().state

  const isMobile = useIsMobile()

  const onClickSignOut = async () => {
    await router.push(ROUTE_ROOT)
    await signOut()
  }

  const setRedirectionCookie = () => {
    setRedirectionCookies(window.location.href)
  }

  return (
    <Menu>
      <MenuButton alignItems='center'>
        <Flex>
          <Image src='/assets/user_dropdown.svg' width={64} height={32} alt='user-dropdown' />
        </Flex>
      </MenuButton>
      <MenuList>
        {user ? (
          <>
            <Link href={getProfileTabURL(USER_PROFILE_TABS.profile)} passHref>
              <MenuItem as='a'>{t('header_user_profile')}</MenuItem>
            </Link>
            <Link href={getProfileTabURL(USER_PROFILE_TABS.subscription)} passHref>
              <MenuItem as='a'>{t('header_user_subscription')}</MenuItem>
            </Link>

            <Link href={getProfileTabURL(USER_PROFILE_TABS.usage)} passHref>
              <MenuItem as='a'>{t('header_user_usage')}</MenuItem>
            </Link>
            <Divider variant='menuSeparator' />
            <MenuItem onClick={onClickSignOut}>{t('header_menu_logout')}</MenuItem>
          </>
        ) : (
          <>
            <Link href={USER_SIGN_IN} passHref>
              <MenuItem
                as='a'
                onClick={() => {
                  setRedirectionCookie()
                }}>
                {t('header_menu_sign_in')}
              </MenuItem>
            </Link>
            <Link href={USER_SIGN_UP} passHref>
              <MenuItem
                onClick={() => {
                  setRedirectionCookie()
                }}>
                {t('header_menu_sign_up')}
              </MenuItem>
            </Link>
          </>
        )}
        <Divider variant='menuSeparator' />
        {isMobile && !user && (
          <Link href={ROUTE_PRICING} passHref>
            <MenuItem as='a'>{t('header_menu_pricing')}</MenuItem>
          </Link>
        )}
        {isMobile && (
          <MenuItem
            as='a'
            href={getCedilleRedirectURL(CEDILLE_HOME, router.locale)}
            target='_blank'
            rel='noopener noreferrer'>
            {t('header_menu_about_us')}
          </MenuItem>
        )}
        <MenuItem
          as='a'
          href={getCedilleRedirectURL(CEDILLE_TERMS_OF_USE, router.locale)}
          target='_blank'
          rel='noopener noreferrer'>
          {t('header_menu_userConditions')}
        </MenuItem>
        <MenuItem
          as='a'
          href={getCedilleRedirectURL(CEDILLE_PRIVACY_POLICY, router.locale)}
          target='_blank'
          rel='noopener noreferrer'>
          {t('header_menu_privacyPolicy')}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default UserMenu
