import {extendTheme} from '@chakra-ui/react'

import Alert from './components/Alert'
import Button from './components/Button'
import Checkbox from './components/Checkbox'
import Divider from './components/Divider'

import Heading from './components/Heading'
import Input from './components/Input'
import Link from './components/Link'
import Menu from './components/Menu'
import Radio from './components/Radio'
import Select from './components/Select'
import Skeleton from './components/Skeleton'
import Switch from './components/Switch'
import Tabs from './components/Tabs'
import Text from './components/Text'
import Tooltip from './components/Tooltip'
import Textarea from './components/Textarea'

import breakpoints from './foundations/breakpoints'
import colors from './foundations/colors'
import fonts from './foundations/fonts'
import fontSizes from './foundations/fontSizes'
import fontWeights from './foundations/fontWeights'
import lineHeights from './foundations/lineHeights'
import radii from './foundations/radii'
import shadows from './foundations/shadows'
import sizes from './foundations/sizes'

const brandTheme = extendTheme({
  fonts,
  sizes,
  breakpoints,
  colors,
  radii,
  fontSizes,
  shadows,
  fontWeights,
  lineHeights,
  components: {
    Text,
    Divider,
    Heading,
    Button,
    Checkbox,
    Radio,
    Switch,
    Menu,
    Tabs,
    Input,
    Select,
    Link,
    Skeleton,
    Tooltip,
    Alert,
    Textarea,
  },
})

export default brandTheme
