const Textarea = {
  variants: {
    playground: {
      color: 'text.basic',
      backgroundColor: 'layers.appLevel2',
      borderRadius: 'small',
      _focus: {
        color: 'text.basic',
        backgroundColor: 'layers.appLevel2',
      },
      _hover: {
        color: 'text.basic',
        backgroundColor: 'layers.appLevel2',
      },
      _invalid: {
        borderColor: 'crimson',
      },
      _active: {
        backgroundColor: 'layers.appLevel2',
      },
      _placeholder: {color: 'text.grey'},
    },
  },
}
export default Textarea
