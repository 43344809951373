import {Button, Heading, HStack, Link, Text, useTheme, VStack} from '@chakra-ui/react'
import {faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CEDILLE_LINKEDIN, CEDILLE_TWITTER} from '../../constants/routes'
import useTranslation from 'next-translate/useTranslation'
import {track} from '../../utils/eventTracking/track'

const Maintenance = () => {
  const theme = useTheme()
  const {t} = useTranslation('common')

  return (
    <VStack spacing={7} w='full' align='start'>
      <Heading color={theme.colors.text.basic} variant='large'>
        {t('maintenance_title')}
      </Heading>
      <VStack
        align={['center', null, 'start']}
        p={5}
        w='full'
        borderRadius={theme.radii.big}
        background={theme.colors.layers.appLevel1}>
        <HStack align='start' justify='start' spacing={4}>
          <VStack align='start' spacing={5}>
            <Heading as='h3' variant='small' color={theme.colors.text.basic}>
              {t('maintenance_description')}
            </Heading>
            <Text color={theme.colors.text.light} variant='medium' mt={0} p={0}>
              {t('maintenance_social')}
            </Text>
            <HStack spacing={6}>
              <Link href={CEDILLE_TWITTER} isExternal>
                <Button
                  variant='textIcon'
                  aria-label='Link to Twitter'
                  onClick={() => track('info-click-twitter')}>
                  <FontAwesomeIcon icon={faTwitter} color={theme.colors.brand.primary} size='2x' />
                </Button>
              </Link>
              <Link href={CEDILLE_LINKEDIN} isExternal>
                <Button variant='textIcon' aria-label='Link to LinkedIn'>
                  <FontAwesomeIcon icon={faLinkedin} color={theme.colors.brand.primary} size='2x' />
                </Button>
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}

export default Maintenance
