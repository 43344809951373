import {AuthChangeEvent, Session} from '@supabase/supabase-js'
import {DependencyList, useEffect} from 'react'
import {useClient} from './useClient'

export function useAuthStateChange(
  callback: (event: AuthChangeEvent, session: Session | null) => void,
  deps?: DependencyList
) {
  const client = useClient()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const {data: authListener} = client.auth.onAuthStateChange(callback)
    return () => {
      authListener?.unsubscribe()
    }
  }, deps)
  /* eslint-enable react-hooks/exhaustive-deps */
}
