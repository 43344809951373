import axiosLib, {AxiosRequestHeaders, InternalAxiosRequestConfig} from 'axios'
import isEmpty from 'lodash/isEmpty'
import {AuthStore} from '../utils/AuthContext'

const axiosAPI = axiosLib.create()

axiosAPI.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const {session} = AuthStore.getState().state
    if (!config.headers) config.headers = {} as AxiosRequestHeaders

    if (!session || isEmpty(session.access_token)) {
      AuthStore.setState({state: {publicUser: null, session: null, user: null}})
      return config as InternalAxiosRequestConfig
    }
    config.headers['Authorization'] = `Bearer ${session.access_token}`
    //if (publicUser?.APIKey) config.headers['X-API-KEY'] = publicUser.APIKey

    return config as InternalAxiosRequestConfig
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

// noinspection JSUnusedGlobalSymbols
export default axiosAPI
