import {SUPPORTED_LANGUAGES} from './languages'

export const ROUTE_ROOT = '/'
export const ROUTE_PRICING = '/pricing'
export const ROUTE_OPEN_MODE_FRENCH = '/model/french'
export const ROUTE_OPEN_MODE_GERMAN = '/model/german'

export const ROUTE_SERVICE_GROUP_SUMMARIZATION = '/summarization'
export const ROUTE_SKILL_SUMMARIZATION = '/summarization/summarization'
export const ROUTE_SKILL_BULLET_POINTS_EXTRACTION = '/summarization/bullet-points-extraction'
export const ROUTE_SKILL_KEYWORDS_EXTRACTION = '/summarization/keywords-extraction'

export const ROUTE_SERVICE_GROUP_REWRITING = '/rewriting'
export const ROUTE_SKILL_FORMAL_REWRITING = '/rewriting/formal-rewriting'
export const ROUTE_SKILL_INFORMAL_REWRITING = '/rewriting/informal-rewriting'
export const ROUTE_SKILL_PARAPHRASING = '/rewriting/paraphrasing'

export const ROUTE_SERVICE_GROUP_COPYWRITING = '/copywriting'
export const ROUTE_SKILL_META_TAGS = '/copywriting/meta-tags'
export const ROUTE_SKILL_PRODUCT_DESCRIPTION = '/copywriting/product-description'
export const ROUTE_SKILL_SEO_OPTIMIZED_TEXT = '/copywriting/extract-seo-optimized-text'

export const ROUTE_SERVICE_GROUP_FIX = '/fix'
export const ROUTE_SKILL_FIX_TEXT = '/fix/text'

export const ROUTE_SERVICE_GROUP_REDACTION = '/redaction'
export const ROUTE_SKILL_STORY_WRITING = '/redaction/story-writing'

export const ROUTE_SERVICE_GROUP_MODELS = '/model'

export const API_ROUTE_MODEL_FRENCH = '/v1/engines/fr-boris/completions'
export const API_ROUTE_MODEL_GERMAN = '/v1/engines/de-anna/completions'

export const STRIPE_CHECKOUT_SESSION = '/api/payment/checkout'
export const STRIPE_PORTAL_SESSION = '/api/payment/portal'
export const STRIPE_USAGE = (userId: string) => `/api/payment/get-usage?userId=${userId}`

export const RESET_PASSWORD = '/api/user/reset-password'

export const SEND_TOXICITY_REPORT_TO_SLACK = '/api/reports/toxicity'
export const SEND_FEEDBACK_TO_SLACK = '/api/reports/feedback-message'

export const USER_PROFILE = '/profile'
export const USER_SIGN_IN = '/profile/sign-in'
export const USER_SIGN_UP = '/profile/sign-up'
export const USER_RESET_PASSWORD = '/profile/reset-password'

export enum USER_PROFILE_TABS {
  profile,
  subscription,
  usage,
  api,
}

export const getProfileTabURL = (index: number) => {
  return `${USER_PROFILE}?index=${index}`
}

export const API_ROUTE_SKILL_SUMMARIZATION = '/api/skill/summarization'
export const API_ROUTE_SKILL_STORY_WRITING = '/api/skill/story-writing'
export const API_ROUTE_SKILL_BULLET_POINTS_EXTRACTION = '/api/skill/bullet-points-extraction'
export const API_ROUTE_SKILL_KEYWORDS_EXTRACTION = '/api/skill/keywords-extraction'
export const API_ROUTE_SKILL_FORMAL_REWRITING = '/api/skill/formal-rewriting'
export const API_ROUTE_SKILL_PARAPHRASING = '/api/skill/paraphrasing'
export const API_ROUTE_SKILL_INFORMAL_REWRITING = '/api/skill/informal-rewriting'
export const API_ROUTE_SKILL_META_TAGS = '/api/skill/meta-tags'
export const API_ROUTE_SKILL_PRODUCT_DESCRIPTION = '/api/skill/product-description'
export const API_ROUTE_SKILL_FIX_TEXT = '/api/skill/fix-text'
export const API_ROUTE_SKILL_WRITE_FREELY = '/api/skill/write-freely'
export const API_ROUTE_SKILL_EXTRACT_SEO_OPTIMIZED_TEXT = '/api/skill/extract-seo-optimized-text'

export const CEDILLE_TWITTER = 'https://twitter.com/CedilleAI'
export const CEDILLE_LINKEDIN = 'https://www.linkedin.com/company/cedilleai/'
// noinspection JSUnusedGlobalSymbols
export const CEDILLE_FACEBOOK = 'https://www.facebook.com/Cedilleai-111194344686865'

export const CEDILLE_EXAMPLE_OF_USE = (slug: string, locale?: string) => {
  const url = `${CEDILLE_EXAMPLES_OF_USES}/${slug}`
  return getCedilleRedirectURL(url, locale)
}

export const getCedilleRedirectURL = (url: string, locale?: string) => {
  switch (locale) {
    case SUPPORTED_LANGUAGES.fr:
      return url.replace('https://', 'https://fr.')
    case SUPPORTED_LANGUAGES.de:
      return url.replace('https://', 'https://de.')
    case undefined:
    default:
      return url
  }
}
export const CEDILLE_PRICING = 'https://cedille.ai/pricing'
export const CEDILLE_EXAMPLES_OF_USES = `https://cedille.ai/examples`
export const CEDILLE_CONTACT = `https://cedille.ai/contact`
export const CEDILLE_PRIVACY_POLICY = `https://cedille.ai/privacy`
export const CEDILLE_TERMS_OF_USE = `https://cedille.ai/terms`
export const CEDILLE_HOME = `https://cedille.ai/`

export const CEDILLE_META_DE =
  'https://pgndvkmjmoacmbcnqwnn.supabase.co/storage/v1/object/public/cedille-storage/cedille_meta_image_de.jpg'

export const CEDILLE_META_EN =
  'https://pgndvkmjmoacmbcnqwnn.supabase.co/storage/v1/object/public/cedille-storage/cedille_meta_image_en.jpg'

export const CEDILLE_META_FR =
  'https://pgndvkmjmoacmbcnqwnn.supabase.co/storage/v1/object/public/cedille-storage/cedille_meta_image_fr.jpg'
