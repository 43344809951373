const fontSize = {
  extraSmallMobile: '11px',
  extraSmallDesktop: '12px',
  smallMobile: '13px',
  smallDesktop: '14px',
  mediumMobile: '15px',
  mediumDesktop: '16px',
  largeMobile: '17px',
  largeDesktop: '18px',
  extraLargeMobile: '19px',
  extraLargeDesktop: '24px',
  extraExtraLargeMobile: '26px',
  extraExtraLargeDesktop: '40px',
}

export default fontSize
