const Input = {
  variants: {
    filled: {
      color: 'text.basic',
      field: {
        height: {base: 10, md: 12},
        color: 'text.basic',
        backgroundColor: 'brand.greyFields',
        borderRadius: 'small',
        border: 0,
        _focus: {
          color: 'text.basic',
          backgroundColor: 'brand.grey',
        },
        _hover: {
          color: 'text.basic',
          backgroundColor: 'brand.greyFields',
        },
        _invalid: {
          borderColor: 'crimson',
        },
        _active: {
          backgroundColor: 'brand.greyFields',
        },
      },
    },
    playground: {
      color: 'text.basic',
      field: {
        height: {base: 10, md: 12},
        color: 'text.basic',
        backgroundColor: 'layers.appLevel2',
        borderRadius: 'small',
        border: 0,
        _focus: {
          color: 'text.basic',
          backgroundColor: 'layers.appLevel2',
        },
        _hover: {
          color: 'text.basic',
          backgroundColor: 'layers.appLevel2',
        },
        _invalid: {
          borderColor: 'crimson',
        },
        _active: {
          backgroundColor: 'layers.appLevel2',
        },
        _placeholder: {color: 'text.grey'},
      },
    },
  },
  defaultProps: {
    variant: 'filled',
  },
}
export default Input
