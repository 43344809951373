import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import {isDev} from '../debug'
import {hasAcceptedGA} from './track'

export const pageView = (pathName: string) => {
  !isDev() && hasAcceptedGA() && ReactGA.send('pageview')
}

///Run at setup before tracking.
export const setup = () => {
  if (!isDev() && process.env.NEXT_PUBLIC_GA_PROPERTY && process.env.NEXT_PUBLIC_GTM_PROPERTY) {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA_PROPERTY)
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_PROPERTY,
    })
  }
}
