const Switch = {
  baseStyle: {
    thumb: {
      bg: 'brand.primary',
      _checked: {
        bg: 'brand.white',
      },
    },
    track: {
      borderColor: 'brand.textColor',
      bg: 'brand.grey',
      _focus: {
        ring: 'none',
      },
      _checked: {
        bg: 'brand.primary',
      },
    },
  },
}

export default Switch
