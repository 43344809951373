const sizes = {
  container: {
    base: 'full',
    lg: 'full',
    xl: '1100px',
  },
  containerPaddingX: {
    base: '15px',
    md: '20px',
    xl: 0,
  },
}

export default sizes
