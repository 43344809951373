const Link = {
  baseStyle: {
    fontSize: {base: 'mediumM', md: 'medium'},
    color: 'brand.primary',
    _focus: {
      ring: 'none',
    },
    _hover: {
      textDecoration: 'none',
    },
  },
}

export default Link
