const Menu = {
  parts: ['button', 'list', 'item'],
  baseStyle: {
    groupTitle: {
      mx: 0,
      fontSize: {base: 'extraSmallM', md: 'extraSmall'},
      lineHeight: '15px',
      fontWeight: 'regular',
      color: 'text.light',
    },
    button: {
      py: '0',
      px: '0',
    },
    list: {
      backgroundColor: 'brand.white',
      borderRadius: 'small',
      p: '10px 20px 10px 20px',
      border: 'none',
      boxShadow: 'normal',
    },
    item: {
      fontSize: ['smallMobile', null, 'smallDesktop'],
      lineHeight: ['smallHeightMobile', null, 'smallHeightDesktop'],
      fontWeight: 'semiBold',
      bg: 'none',
      w: 'full',
      p: '10px 0 10px 0',
      m: 0,
      color: 'text.dark',
      _hover: {
        opacity: '0.75',
        bg: 'none',
      },
      _focus: {
        bg: 'none',
      },
    },
  },
}

export default Menu
