const lineHeights = {
  extraSmallHeightMobile: '13px',
  extraSmallHeightDesktop: '15px',
  smallHeightMobile: '16px',
  smallHeightDesktop: '17px',
  mediumHeight: '24px',
  mediumHeightMobile: '21px',
  mediumHeightDesktop: '22px',
  largeHeightMobile: '23px',
  largeHeightDesktop: '29px',
  extraLargeHeightMobile: '31px',
  extraLargeHeightDesktop: '48px',
}
export default lineHeights
