const Divider = {
  baseStyle: {
    background: 'brand.grey',
    height: '1.5px',
    borderBottomWidth: '0 !important',
    opacity: '1',
  },
  variants: {
    menuSeparator: {
      my: 2.5,
      background: 'brand.greyLight',
    },
    separator2: {
      background:
        'linear-gradient(90deg, rgba(43, 41, 46, 0) 0%, rgba(43, 41, 46, 0.9) 50%, rgba(43, 41, 46, 0) 100%)',
      opacity: '1',
      height: '2.5px',
    },
    separator3: {
      background: 'linear-gradient(90deg, rgba(43, 41, 46,0.9) 0%, rgba(43, 41, 46,0) 100%)',
      opacity: '1',
      height: '1.5px',
    },
    separatorLight: {
      background: 'brand.greyLight',
      height: '1px',
    },
  },
}
export default Divider
