const Tabs = {
  parts: ['tab', 'tablist', 'tabpanel'],
  variants: {
    primary: {
      tab: {
        fontSize: {base: 'mediumM', md: 'medium'},
        lineHeight: 'mediumHeight',
        fontWeight: 'semiBold',
        color: 'text.grey',
        borderBottomWidth: '2px',
        borderBottomColor: 'transparent',
        borderRadius: '1px',
        mx: 4,
        px: 1,
        pb: 2,
        _selected: {
          ring: 'none',
          color: 'brand.primary',
          borderBottomColor: 'brand.primary',
          borderBottomWidth: '2px',
        },
      },
      tablist: {
        mx: 5,
      },
      tabpanel: {
        p: 0,
        pt: 7,
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
}

export default Tabs
