const Radio = {
  baseStyle: {
    label: {
      color: 'text.basic',
      overflowWrap: 'anywhere',
      fontSize: {base: 'mediumM', md: 'medium'},
      lineHeight: 'mediumHeight',
    },
    control: {
      background: 'brand.white',
      transition: 'all, 150ms',
      borderWidth: 0,
      _hover: {
        background: 'brand.primary',
      },
      _checked: {
        background: 'brand.primary',
      },
    },
  },
}
export default Radio
