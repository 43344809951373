// noinspection JSUnusedGlobalSymbols

const Button = {
  baseStyle: {
    fontSize: {base: 'mediumM', md: 'medium'},
    lineHeight: 'mediumHeight',
    fontWeight: 'semiBold',
    overflowWrap: 'anywhere',
    transition: 'all 150ms',
    flexShrink: 0,

    _focus: {
      ring: 'none',
    },
    _hover: {
      textDecoration: 'none',
    },
  },
  sizes: {
    sl: {height: {base: 'auto', md: 'auto'}},
    md: {height: {base: '40px', md: '44px'}, borderRadius: 'small'},
    lg: {height: {base: '50px', md: '54px'}, borderRadius: 'small'},
  },
  variants: {
    primary: {
      '*': {
        height: 3,
      },
      background: 'brand.primary',
      color: 'text.basic',
      transition: 'all 0.3s ease',
      px: 6,
      _hover: {
        filter: 'brightness(105%)',
        transform: 'scale(1.05)',
        _disabled: {
          background: 'brand.grey',
        },
      },
      _disabled: {
        opacity: 1,
        background: 'brand.grey',
        color: 'text.dark',
      },
    },
    clipboard: {
      p: 3,
      h: 9,
      opacity: 1,
      fontSize: {base: 'smallM', md: 'small'},
      lineHeight: '16px',
      fontWeight: 'semiBold',
      background: 'layers.appLevel2',
      color: 'brand.grey',
    },
    white: {
      pt: -1,
      pb: 1,
      '*': {
        height: 3,
        mb: -1,
      },
      px: 6,
      background: 'brand.white',
      color: 'brand.primary',
    },
    whiteError: {
      pt: -1,
      pb: 1,
      '*': {
        height: 3,
        mb: -1,
      },
      px: 6,
      background: 'brand.white',
      color: 'brand.error',
    },
    back: {
      background: 'layers.appLevel1',
      color: 'text.basic',
      borderRadius: 'infinite',
      height: {base: '40px', md: '54px'},
      width: {base: '40px', md: '54px'},
      minW: {base: '40px', md: '54px'},
      padding: 0,
      fontSize: {base: '14px', md: '20px'},
      _hover: {
        background: 'layers.appLevel2',
        fontSize: {base: '18px', md: '23px'},
      },
    },
    textLarge: {
      p: 0,
      height: 'auto',
      color: 'brand.primary',
      fontSize: {base: 'largeM', md: 'large'},
      fontWeight: 'regular',
      textDecoration: 'underline',
      lineHeight: 'mediumHeight',
      _hover: {
        color: 'text.basic',
      },
    },
    textNormal: {
      p: 0,
      height: 'auto',
      color: 'text.dark',
      _hover: {
        color: 'text.basic',
      },
    },
    textIcon: {
      p: 1,
      minW: 0,
      height: 'auto',
      color: 'text.grey',
      fontSize: '18px',
      _hover: {
        color: 'text.basic',
      },
    },
    textLightPrimary: {
      p: 0,
      height: 'auto',
      color: 'brand.primary',
      flexShrink: 0,
      fontWeight: 'regular',
      _hover: {
        color: 'text.basic',
      },
    },
    textLightWhite: {
      p: 0,
      m: 0,
      fontWeight: 'regular',
      flexShrink: 0,
      height: 'auto',
      color: 'text.basic',
      _hover: {
        opacity: '0.7',
      },
      _active: {
        opacity: '1',
      },
    },
    textLight: {
      p: 0,
      height: 'auto',
      color: 'text.light',
      flexShrink: 0,
      fontWeight: 'regular',
      _hover: {
        color: 'text.basic',
      },
      _disabled: {
        color: 'text.light',
        opacity: 1,
      },
    },
    modelCard: {
      width: {base: 16, md: 20},
      height: {base: 16, md: 20},
      borderRadius: 'infinite',
      fontWeight: 600,
      fontSize: '21px',

      lineHeight: '25px',
      color: 'text.basic',
      overflow: 'hidden',
      _hover: {
        fontSize: '25px',
      },
      _disabled: {
        opacity: 1,
      },
    },
    loginButton: {
      width: 'full',
      background: 'brand.primary',
      color: 'text.basic',
      _hover: {
        filter: 'brightness(110%)',
        _disabled: {
          background: 'brand.grey',
        },
      },
      _disabled: {
        opacity: 1,
        background: 'brand.grey',
        color: 'text.dark',
      },
    },
    switchInnerButton: {
      borderRadius: 'medium',
      fontWeight: 'small',
      pt: 1,
      pb: 1,
      pl: 4,
      pr: 4,
      height: {base: 'auto', md: 'auto'},
      color: 'text.basic',
    },
    socialLogin: {
      width: 'full',
      background: 'brand.primary',
      color: 'text.basic',
      _hover: {
        filter: 'brightness(110%)',
        _disabled: {
          background: 'brand.grey',
        },
      },
    },
    dark: {
      px: 6,
      background: 'layers.appLevel2',
      color: 'text.basic',
      width: {base: 'full', md: 'auto'},
      _hover: {transform: 'scale(1.05)'},
    },
  },
}

export default Button
