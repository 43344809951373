import {Box, Container, useTheme, VStack} from '@chakra-ui/react'
import {useRouter} from 'next/router'
import {ReactNode} from 'react'
import NavBar from '../Modules/NavBar'

interface Props {
  children: ReactNode
}

const MainLayout = ({children}: Props) => {
  const theme = useTheme()
  const router = useRouter()
  if (router.asPath.startsWith('/b2b') || router.asPath.startsWith('/api-docs')) {
    return <>{children}</>
  } else
    return (
      <>
        <Box
          position='fixed'
          top={0}
          left={0}
          right={0}
          bottom={0}
          background={theme.colors.layers.appBackground}
          zIndex={-1000000000000}
          m={0}
        />
        <VStack m={0} w='full' minH='100vh' maxW='100vw' spacing={8}>
          <NavBar />
          <Container
            maxW={theme.sizes.container}
            px={theme.sizes.containerPaddingX}
            mt={20}
            pb={20}>
            {children}
          </Container>
        </VStack>
      </>
    )
}

export default MainLayout
