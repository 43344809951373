import {zPublicUser} from '@/dataModels/PublicUser'
import {
  COOKIE_INPUTS,
  COOKIE_OPTIONS,
  COOKIE_TEXT_LANGUAGE,
  COOKIES_NEED_REDIRECTION,
} from '../constants/cookies'
import {SUPPORTED_LANGUAGES} from '../constants/languages'
import {ROUTE_PRICING, ROUTE_ROOT} from '../constants/routes'
import axios from '../fetchers/axiosAPI'
import modelStore from '../stores/modelStore'
import {createCookie} from './cookieHelpers'

enum envs {
  LOCAL = 'local',
  DEV = 'preview',
  PROD = 'production',
}

export const getRedirectTo = () => {
  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case envs.PROD:
      return 'https://app.cedille.ai'
    case envs.DEV:
      if (window.location.origin.includes('privatebeta')) return 'https://privatebeta.cedille.ai'
      return 'https://dev.cedille.ai'
    default:
    case envs.LOCAL:
      return 'http://localhost:3000'
  }
}

export const setRedirectionCookies = (
  fromPath?: string,
  inputsToRecover?: any,
  optionsToRecover?: any,
  textLanguageToRecover?: SUPPORTED_LANGUAGES
) => {
  if (fromPath) {
    if (fromPath.includes(ROUTE_PRICING)) fromPath = fromPath.replace(ROUTE_PRICING, ROUTE_ROOT)
    const modelInput = modelStore.getState().input
    const modelTemperature = modelStore.getState().temperature
    const modelTopP = modelStore.getState().topP
    const modelMaxLength = modelStore.getState().maxLength

    if (modelInput) {
      createCookie(
        COOKIE_INPUTS,
        JSON.stringify({
          input: modelInput,
          temperature: modelTemperature,
          topP: modelTopP,
          maxLength: modelMaxLength,
        })
      )
    }

    createCookie(COOKIES_NEED_REDIRECTION, fromPath)
    inputsToRecover && createCookie(COOKIE_INPUTS, JSON.stringify(inputsToRecover))
    optionsToRecover && createCookie(COOKIE_OPTIONS, JSON.stringify(optionsToRecover))
    textLanguageToRecover &&
      createCookie(COOKIE_TEXT_LANGUAGE, JSON.stringify(textLanguageToRecover))
  }
}

export const fetchMe = async (language: SUPPORTED_LANGUAGES) => {
  try {
    const response = await axios.get('/api/auth/me', {
      headers: {
        'X-USER-LANG': language,
      },
    })
    return zPublicUser.parse(response.data)
  } catch (e: any) {
    console.log(e)
    return null
  }
}
