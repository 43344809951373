import {User} from '@supabase/supabase-js'
import dayjs from 'dayjs'
import {parseCookies} from 'nookies'
import ReactGA from 'react-ga4'
import {isDev} from '../debug'
import Router from 'next/router'

export const hasAcceptedGA = () => {
  const {axeptio_authorized_vendors: cookie} = parseCookies()

  return cookie?.includes('google_analytics') ?? false
}

export const handleUserLogin = (user: User) => {
  const isNewUser = dayjs(user.created_at).isSame(dayjs(user.last_sign_in_at), 'minute')

  if (isNewUser) {
    const currentLanguage = Router.locale
    if (currentLanguage) {
      track(`user_signup_${currentLanguage}`)
    } else {
      track('user_signup')
    }
  } else {
    track('user_signin')
  }
}

export const track = (event: string) => {
  if (!isDev()) {
    hasAcceptedGA() && ReactGA.event(event)
  } else {
    console.info('Tracking', event)
  }
}
