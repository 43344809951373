const Alert = {
  variants: {
    success: {
      container: {
        color: 'text.basic',
        bg: 'brand.success',
        fontSize: ['mediumMobile', null, 'mediumDesktop'],
      },
    },
    error: {
      container: {
        color: 'text.basic',
        bg: 'brand.error',
        fontSize: ['mediumMobile', null, 'mediumDesktop'],
      },
    },
    warning: {
      container: {
        color: 'text.basic',
        bg: 'brand.warning',
        fontSize: ['mediumMobile', null, 'mediumDesktop'],
      },
    },
  },
}
export default Alert
