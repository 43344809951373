const colors = {
  transparent: '#00000000',
  brand: {
    primary: 'rgba(88, 169, 255, 0.9)',
    gradientPrimary: 'linear-gradient(275.63deg, #C5508A 1.15%, #444090 94.27%)',
    gradientPrimaryLight: 'linear-gradient(275.63deg, #EE64A8 1.15%, #5752AE 94.27%)',
    grey: 'rgba(43, 41, 46, 0.9)',
    greyLight: '#DDDAEB',
    greyFields: '#3F3E45',
    white: '#FFF',
    error: '#FA8484',
    warning: '#FCA9A9',
    success: '#30CDB1',
  },
  text: {
    basic: '#FFF',
    light: '#C7C3D9',
    grey: '#797785',
    dark: '#46454D',
  },
  layers: {
    appBackground: 'linear-gradient(113.92deg, #282653 1.13%, #472632 61.71%, #582E3D 98.87%)',
    appLevel1: 'rgba(5, 4, 5, 0.2)',
    appLevel2: 'rgba(28, 26, 31, 0.9)',
    appLevel3: ' rgba(23, 22, 26, 0.9)',
    modalBackground: '#17171A',
  },
  services: {
    gradientRed: 'linear-gradient(292.8deg, #FF5880 0%, #FF9D87 100%)',
    gradientOrange: 'linear-gradient(292.8deg, #FF7D95 0%, #FFC8A9 100%)',
    gradientPurple: 'linear-gradient(112.81deg, #F77FB3 0%, #B765D7 99.99%)',
    gradientBlue: 'linear-gradient(112.81deg, #87F0FE 0%, #72ADFF 100%)',
    gradientGreen: 'linear-gradient(112.72deg, #80F7DA 0%, #30CDCD 100%)',
    red: '#FF5880',
    orange: '#FF7D95',
    purple: '#F77FB3',
    blue: '#87F0FE',
    green: '#80F7DA',
  },
}
export default colors
