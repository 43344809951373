import {
  Box,
  Button,
  HStack,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faGlobe} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import {useRouter} from 'next/router'
import {isSupported, SUPPORTED_LANGUAGES} from '../../constants/languages'
import {
  CEDILLE_HOME,
  CEDILLE_TWITTER,
  getCedilleRedirectURL,
  ROUTE_PRICING,
  ROUTE_ROOT,
} from '../../constants/routes'
import {track} from '../../utils/eventTracking/track'
import UserMenu from './UserMenu'
import Link from 'next/link'
import {useIsMobile} from '../../hooks/useIsMobile'

const NavBar = () => {
  const theme = useTheme()
  const router = useRouter()
  const {t} = useTranslation('menu')
  const logoHeight = useBreakpointValue([38, null, 44])
  const isMobile = useIsMobile()

  const handleLogoClick = async () => {
    if (router.pathname !== ROUTE_ROOT) {
      await router.push(ROUTE_ROOT)
    }
  }

  const changeLanguage = async (lang: SUPPORTED_LANGUAGES) => {
    if (isSupported(lang)) {
      await router.push(router.asPath, undefined, {locale: lang})
    }
  }

  const getActualLanguage = (lang: string) => {
    return router.locale === lang && theme.colors.brand.primary
  }

  return (
    <HStack h='85px' w='100%' px={4} justify='space-between'>
      <Box cursor='pointer'>
        <Image
          onClick={handleLogoClick}
          src='/assets/headerLogo.svg'
          height={logoHeight || 40}
          width={125}
          alt='logo-cedille'
        />
      </Box>
      <HStack w='content-fit' spacing={{base: 5, md: 8}}>
        {!isMobile && (
          <>
            <Link href={ROUTE_PRICING} passHref>
              <Button as='a' variant='textLightWhite'>
                {t('header_menu_pricing')}
              </Button>
            </Link>
            <ChakraLink href={getCedilleRedirectURL(CEDILLE_HOME, router.locale)} isExternal>
              <Button variant='textLightWhite'>{t('header_menu_about_us')}</Button>
            </ChakraLink>
          </>
        )}

        <ChakraLink href={CEDILLE_TWITTER} isExternal>
          <Button
            variant='textIcon'
            onClick={() => track('info-click-twitter')}
            aria-label='ChakraLink to Twitter'>
            <FontAwesomeIcon icon={faTwitter} color={theme.colors.brand.white} />
          </Button>
        </ChakraLink>
        <Menu>
          <MenuButton aria-label='Change language'>
            <FontAwesomeIcon icon={faGlobe} color={theme.colors.brand.white} />
          </MenuButton>
          <MenuList>
            <MenuGroup
              title={t('language_switcher_title')}
              fontSize={theme.components.Text.variants.extraSmall.fontSize}>
              <MenuItem
                color={getActualLanguage(SUPPORTED_LANGUAGES.en)}
                onClick={() => changeLanguage(SUPPORTED_LANGUAGES.en)}>
                {t('language_switcher_english')}
              </MenuItem>
              <MenuItem
                color={getActualLanguage(SUPPORTED_LANGUAGES.fr)}
                onClick={() => changeLanguage(SUPPORTED_LANGUAGES.fr)}>
                {t('language_switcher_french')}
              </MenuItem>
              <MenuItem
                color={getActualLanguage(SUPPORTED_LANGUAGES.de)}
                onClick={() => changeLanguage(SUPPORTED_LANGUAGES.de)}>
                {t('language_switcher_german')}
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
        <UserMenu />
      </HStack>
    </HStack>
  )
}
export default NavBar
