import {z} from 'zod'

export default class PublicUser {
  public id: string
  public stripeSubscriptionId?: string
  public stripeCustomerId?: string
  public APIKey: string
  public email: string
  public existInSendinBlue: boolean
  public name?: string
  public isPartner: boolean = false

  constructor(
    id: string,
    stripe_subscription_id: string,
    stripe_customer_id: string,
    api_key: string,
    email: string,
    existInSendinBlue: boolean
  ) {
    this.id = id
    this.stripeCustomerId = stripe_customer_id
    this.stripeSubscriptionId = stripe_subscription_id
    this.APIKey = api_key
    this.email = email
    this.existInSendinBlue = existInSendinBlue
  }
}

export const zPublicUser = z.object({
  id: z.string(),
  stripeSubscriptionId: z.string().nullish(),
  stripeCustomerId: z.string().nullish(),
  APIKey: z.string(),
  email: z.string(),
  existInSendinBlue: z.boolean(),
  name: z.string().nullish(),
  isPartner: z.boolean().default(false),
})

export type zPublicUser = z.infer<typeof zPublicUser>
